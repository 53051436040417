.employee {
    position: relative;
   
    margin-bottom: 30px;
    margin-top: 30px;

    img {
        width: 100%;
        box-shadow: 20px 35px 60px 0px rgba(0, 0, 0, 0.1);
        margin-bottom: 10px;
    }

    .employee_content {
        padding: 15px 15px 15px 15px;
        line-height: 1.5;

        h2 {
            font-size: 20px;
            margin-bottom: 5px;
        }

        h3 {
            font-family: $font2;
            font-weight: normal;
            font-size: 18px;
            color: #999;
            margin-bottom: 15px;
        }

        .employee_contact {
            a.phone {
               
                color: $darkgreen;
                letter-spacing: 1px;

                span {
                    display: inline-block;
                    font-size: 18px;
                    vertical-align: -3px;
                }

                &.second {
                    margin-left: 10px;
                }
            }

            .btn {
                background: none;
                text-transform: uppercase;
                text-decoration: none;
                padding: 8px 15px;
                margin-top: 5px;
                margin-left: -15px;
                border-bottom: 2px solid $color2;
                border-radius: 0;
                text-align: left;
                color: $color2;
                font-size: 16px;
                letter-spacing: 1px;

                &:hover{
                    color: $color1;
                    border-bottom: 2px solid $color1;
                }
            }
        }
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {

    .employee {
        .employee_content {
            padding: 15px 15px 90px 15px;

            .employee_contact {
                position: absolute;
                bottom: 15px;
                left: 15px;
            }
        }
    }

}

// *****************************************************************
// LG
// *****************************************************************

@media (min-width: $screen-lg-min) {
    .employee {
        .employee_content {
            padding: 15px 15px 110px 15px;

            .employee_contact {
                a.phone {
                    display: block;

                    &.second {
                        margin-left: 0px;
                    }
                }
            }
        }
    }
}

// *****************************************************************
// 1440
// *****************************************************************

@media (min-width: 1440px) {
    .employee {
        .employee_content {
            padding: 15px 15px 110px 15px;

            .employee_contact {
                position: absolute;
                bottom: 15px;
                left: 15px;

                a.phone {
                    display: inline-block;

                    // &.second {
                    //     margin-left: 10px;
                    // }
                }
            }
        }
    }
}