#show {
    .container {
        position: relative;
        top: -30px;
        .text {
            // padding-top: 30px;
            img.top {
                width: 100%;
                height: auto;
                box-shadow: 20px 35px 60px 0px rgba(0, 0, 0, 0.1);
                margin-bottom: 60px;
            }
            ul {
                padding: 0 20px;
            }
            h3 {
                font-size: 20px;
                font-weight: 600;
                margin-bottom: 5px;
                margin-top: 20px;
                color: $color2;
                letter-spacing: 1px;
            }
            .btn {
                background: none;
                text-transform: uppercase;
                text-decoration: none;
                padding: 8px 15px;
                margin-top: 5px;
                margin-left: -15px;
                border-bottom: 2px solid #000;
                border-radius: 0;
                text-align: left;
                color: #000;
                font-size: 16px;
                letter-spacing: 1px;
                &:hover {
                    color: $color2;
                    border-bottom: 2px solid $color2;
                }
            }
        }
        .contact-side {
            background: $color1;
            color: $darkgreen;
            margin-top: 30px;
            padding: 30px;
            font-size: 16px;
            box-shadow: 20px 35px 60px 0px rgba(0, 0, 0, 0.1);
            h2 {
                font-size: 30px;
                font-weight: 900;
            }
            a {
                color: $darkgreen;
                text-decoration: underline;
                &:hover {
                    color: #fff;
                    border-color: #fff;
                }
            }
            .btn {
                // background: none;
                // text-transform: uppercase;
                // text-decoration: none;
                color: #fff;
                text-decoration: none;
                padding: 15px 15px;
                margin-left: -15px;
                // border-bottom: 2px solid $darkgreen;
                // border-radius: 0;
                // text-align: left;
                // color: $darkgreen;
                // font-size: 16px;
                // letter-spacing: 1px;
                &:hover {
                    background: #fff;
                    color: $darkgreen;
                }
            }
        }
        .social-side {
            padding: 30px;
            font-weight: 600;
            p {
                margin-bottom: 15px;
            }
            ul {
                display: block;
                overflow: hidden;
                margin: 0;
                padding: 0;
                list-style: none;
                li {
                    float: left;
                    a {
                        display: block;
                        color: $darkgreen;
                        height: 40px;
                        width: 40px;
                        border: 2px solid $darkgreen;
                        text-align: center;
                        line-height: 40px;
                        font-size: 24px;
                        margin-right: 15px;
                        margin-bottom: 15px;
                        transition: all 300ms ease;
                        &:hover {
                            color: $color1;
                            border-color: $color1;
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #show {
        .container {
            .contact-side {
                padding: 40px
            }
            .social-side {
                padding: 40px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #show {
        .container {
            top: -60px;
            .text {
                h1,
                h2,
                h3,
                h4,
                ul,
                ol,
                p,
                form,
                small {
                    margin-left: 30px;
                }
            }
            .contact-side {
                margin-top: 0;
                h2 {
                    font-size: 50px;
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    #show {
        .container {
            top: -60px;
            .contact-side,
            .social-side {
                padding: 60px;
                margin-top: 30px;
            }
            .contact-side {
                h2 {
                    font-size: 50px;
                }
            }
        }
    }
}