* {
    box-sizing: border-box;
    outline: 0;
}

html {
    scroll-behavior: smooth;
}

.container {
    padding: 0 !important;
    width: 100% !important;
    max-width: 1440px !important;
}

body {
    font-family: $font2;
    font-size: 14px;
    line-height: 1.6;
    color: #16321c;

    .container-fluid {
        margin: 0 auto;
        min-width: 320px;
        // max-width: 1440px;

        // &.container-fluid-wide {
        //     max-width: 1810px;
        // }
    }

    &.nav-open {
        overflow: hidden;
    }
}

.container-fluid {
    &.max-width {
        max-width: 1440px;
    }
}

h1, h2, h3 {
    font-family: $font1;
    font-size: 28px;
    font-weight: 700;
    margin: 0 0 15px 0;
    color: $darkgreen;
    line-height: 1.2;
}

h2 {
    font-size: 18px;
}

h3 {
    margin: 0 0 10px 0;
    font-size: 18px;
}

a {
    color: $color1;
    text-decoration: none;

    &:hover {
        color: $color1-hover;
    }
}

.clearfix {
    &::after {
        display: block;
        content: "";
        clear: both;
    }
}

.img-responsive {
    max-width: 100%;
}

.wrapper {
    padding: 45px 0;

    &.wrapper-gray {
        background: #f2f2f2;
    }

    &.wrapper-white {
        background: #fff;
    }

    &.wrapper-color1 {
        background: $color1;
       
        h2 {
            color: #FFF;
        }
    }

    &.wrapper-color2 {
        background: $color2;
        color: #FFF;
    }

    &.wrapper-color3 {
        background: $color1;
        color: #FFF;
    }
}

.content {
    margin: 0 30px;
    background: #fff;
    img{
        max-width: 100%;
    }
}

p {
    margin: 0 0 30px 0;
}

figure {
    margin: 0;
}

.pull-left {
    float: left !important;
}

.pull-right {
    float: right !important;
}

.text-left,
.text-xs-left {
    text-align: left !important;
}

.text-right,
.text-xs-right {
    text-align: right !important;
}

.text-center,
.text-xs-center {
    text-align: center !important;
}

.no-margin-bottom {
    margin-bottom: 0px !important;
}

.no-padding-top {
    padding-top: 0px !important;
}

.no-padding-bottom {
    padding-bottom: 0px !important;
}

.with-padding-bottom {
    padding-bottom: 30px;
}

blockquote {
    position: relative;
    padding: 15px 30px 15px 45px;
    margin: 0 0 30px 0;
    
    &:before {
        content: '';
        position: absolute;
        left: 15px;
        top: 0;
        height: 100%;
        width: 2px;
        background: #C1BDB4;
    }
    
    p {
        color: $color2;
        margin: 0;
        font-size: 16px;

        &:before {
            content: '\201C';
        }
        &:after {
            content: '\201D';
        }
    }

    span {
        display: block;
        margin-top: 15px;
        color: #C1BDB4;
        text-transform: uppercase;
    }
}

form.form-horizontal {
    .control-label {
        text-align: left;
    }

    .g-recaptcha {
        margin-left: 15px;
    }
}

// *****************************************************************
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {

    h1, h2, h3 {
        margin: 0 0 30px 0;
        font-size: 32px;
    }

    h2 {
        font-size: 24px;
    }

    h3 {
        margin: 0;
        font-size: 20px;
    }

    blockquote { 
        max-width: 70%;

        p {
            font-size: 20px;
            line-height: 30px;
        }

        span {
            font-size: 16px;
            letter-spacing: 1px;
        }
    }

    .text-sm-left {
        text-align: left !important;
    }

    .text-sm-right {
        text-align: right !important;
    }

    .text-sm-center {
        text-align: center !important;
    }

    form.form-horizontal {
        .g-recaptcha {
            margin-left: calc(25% + 15px);
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {

    body {
        font-size: 16px;
    }

    blockquote { 
        max-width: 85%;
        left: 50;

        p{
            font-size: 22px;
        }

        span{
            margin-left: 30px;
        }

        &:before {
            left: 45px;
        }
    }

    .wrapper {
        padding: 60px 0;
    }

    .text-md-left {
        text-align: left !important;
    }

    .text-md-right {
        text-align: right !important;
    }

    .text-md-center {
        text-align: center !important;
    }

    .with-padding-bottom {
        padding-bottom: 45px;
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {

    h1, h2, h3 {
        font-size: 40px;
    }

    h2 {
        font-size: 28px;
    }

    h3 {
        font-size: 26px;
    }

    .wrapper {
        padding: 100px 0;
    }

    blockquote { 
        max-width: 65%;
        
        
        p{
            font-size: 24px;
            padding-left: 15px;
        }

        span{
            margin-left: 45px;
        }

        &:before {
            left: 60px;
        }
    }

    .text-lg-left {
        text-align: left !important;
    }

    .text-lg-right {
        text-align: right !important;
    }

    .text-lg-center {
        text-align: center !important;
    }

    .with-padding-bottom {
        padding-bottom: 60px;
    }
}