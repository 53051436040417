.btn {
    display: inline-block;
    background: #FFF;
    color: $color1;
    padding: 15px;
    font-weight: 700;
    font-size: 18px;
   
    text-decoration: none;
    white-space: nowrap;
    border: none;
    transition: all 200ms ease;
    text-align: center;

    &:hover {
        text-decoration: none;
        background: $color2;
        color: $color1-text;
    }

    &.btn-color1 {
        background: $color1;
        color: $color1-text;

        &:hover {
            background: $color2-hover;
        }

    }

    &.btn-color2 {
        background: $color2;
        color: $color2-text;

        &:hover {
            background: $color2-hover;
        }

    }

    &.btn-gray {
        border: 1px solid #cbcbce;
        background: $color-gray;
        color: #202020;
    }

    &.btn-min-width {
        min-width: 250px;
    }

    &.btn-100 {
        width: 100%;
    }

    &.btn-line {
        background: none;
        box-shadow: inset 0 0 0 2px #fff;
        color: #FFF;
    }

    i {
        margin-left: 5px;
    }
}


.dropdown {
    position: relative;

    .btn {
        position: relative;
        min-width: 220px;
        text-align: left;
        font-weight: normal;
        height: 58px;
        padding: 15px;

        .icon {
            position: absolute;
            top: 18px;
            right: 15px;
            font-size: 16px;
        }
    }

    ul {
        display: none;
        position: absolute;
        left: 0;
        top: 58px;
        z-index: 10;
        width: 100%;
        list-style: none;
        border: 1px solid #cbcbce;
        background: $color-gray;
        border-radius: 5px;
        margin: 0;
        padding: 0;
        font-size: 18px;

        li {
            a {
                display: block;
                padding: 5px 15px;
                color: #202020;

                &:hover {
                    background: rgba(0, 0, 0, 0.1);
                }
            }
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {

}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
    .btn {
        padding: 15px 30px;
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
}