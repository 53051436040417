#objects {
    margin: 0 30px;
    position: relative;
    top: -355px;
    margin-bottom: -355px;
    overflow: hidden;

    .content-text {
        padding: 0 15px;
        margin-bottom: 30px;

        p {
            margin: 0;
        }

        .col-xs-3 {
            position: relative;

            i {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                font-size: 25px;
                color: #C0BCB2;
            }
        }
    }
    
    .sort {
        margin-bottom: 30px;

        p {
            display: none;
        }

        .dropdown {
            margin: 0 auto;
            width: 220px;
        }
    }

    .object_wrapper {
        margin-bottom: 30px;
    }
}

// *****************************************************************
// SM
// *****************************************************************

@media (min-width: $screen-sm-min) {
    #objects {
        top: -325px;
        margin-bottom: -325px;

        h1 {
            float: left;
            line-height: 58px;
        }

        h2 {
            font-size: 16px
        }

        h3 {
            font-size: 32px
        }

        .content-text {
            .col-xs-3 {
                border-right: 1px solid #C0BCB2;
            }
        }

        .sort {
            float: right;
            margin-bottom: 0;

            p {
                display: block;
                float: left;
                padding: 15px 0;
                margin-right: 15px;
                margin-bottom: 0;
            }

            .dropdown {
                float: left;
                margin: 0;
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************

@media (min-width: $screen-md-min) {
    #objects {
        top: -429px;
        margin-bottom: -429px;

        .content-text {
            margin-bottom: 60px;
        }

        .object_wrapper {
            &:nth-child(odd) {
                padding-right: 30px;
            }
            &:nth-child(even) {
                padding-left: 30px;
            }
        }
    }
}

// *****************************************************************
// LG
// *****************************************************************

@media (min-width: $screen-lg-min) {
    #objects {
        top: -554px;
        margin-bottom: -554px;
    }
}

//

//
@media (min-width: 1500px) {
    #objects {
        top: -572px;
        margin-bottom: -572px;
    }
}