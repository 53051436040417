@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?zgd1l9');
  src:  url('fonts/icomoon.eot?zgd1l9#iefix') format('embedded-opentype'),
  url('fonts/icomoon.ttf?zgd1l9') format('truetype'),
  url('fonts/icomoon.woff?zgd1l9') format('woff'),
  url('fonts/icomoon.svg?zgd1l9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-vgc:before {
  content: "\e90b";
}
.icon-nrvt:before {
  content: "\e90c";
}
.icon-arrow-left:before {
  content: "\e919";
}
.icon-arrow-right:before {
  content: "\e91a";
}
.icon-funda:before {
  content: "\e918";
}
.icon-menu:before {
  content: "\e90d";
}
.icon-kennisinhuis:before {
  content: "\e913";
  color: #fff;
}
.icon-vastgoed-gecertificeerd:before {
  content: "\e914";
}
.icon-nwwi:before {
  content: "\e915";
}
.icon-nvm:before {
  content: "\e916";
}
.icon-size:before {
  content: "\e900";
}
.icon-eye:before {
  content: "\e901";
}
.icon-home:before {
  content: "\e912";
}
.icon-instagram:before {
  content: "\e902";
}
.icon-youtube:before {
  content: "\e903";
}
.icon-twitter:before {
  content: "\e904";
}
.icon-facebook:before {
  content: "\e905";
}
.icon-linkedin:before {
  content: "\e906";
}
.icon-chevron-right:before {
  content: "\e907";
}
.icon-chevron-left:before {
  content: "\e908";
}
.icon-chevron-down:before {
  content: "\e909";
}
.icon-chevron-up:before {
  content: "\e90a";
}
.icon-arrow-down:before {
  content: "\e90e";
}
.icon-arrow-up:before {
  content: "\e90f";
}
.icon-bed:before {
  content: "\e910";
}
.icon-check:before {
  content: "\e911";
}
.icon-phone:before {
  content: "\e917";
}
