.pagination {
    list-style: none;

    li {
        font-family: $font2;
        font-size: 18px;
        display: inline-block;
        width: 50px;
        height: 50px;
        line-height: 50px;
        border-radius: 5px;
        margin: 0 5px;
        box-shadow: inset 0px 0px 0px 1px #d8d2ef;


        a {
            display: block;
            width: 50px;
            height: 50px;
            line-height: 50px;
            border-radius: 5px;
            color: #202020;
            text-decoration: none;

            &:hover {
                border: none;
                color: #202020;
                background: #f2f2f6;
            }
        }

        &:hover {
            box-shadow: none;
        }

        &.disabled {
            color: #CCC;
            background: none;
        }

        &.active {
            box-shadow: none;
            font-weight: bold;
            color: #202020;
            background: #f2f2f6;
        }

        &:first-child,
        &:last-child {
            box-shadow: none;
            width: 20px;

            a {
                width: 20px;

                &:hover {
                    background: none;
                }
            }
        }
    }
}