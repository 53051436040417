footer {
    font-size: 16px;
    padding-top: 90px !important;

    .top {
        position: relative;
        z-index: 1;
        background: $color1;
        padding: 0 0 30px;
        min-height: 200px;

        .logo {
            width: 260px;
        }

        a {
            color: $darkgreen;
            text-decoration: underline;

            &:hover {
                color: #fff; 
            }
        }

        h3 {
            font-family: $font1;
            font-size: 34px;
            color: $darkgreen;
            margin-bottom: 20px;
            margin-top: 60px;
            margin-left: 15px;
        }
        p {
            padding-left: 15px;
        }

        #mc_embed_signup {
            margin-right: 30px;

            form {
                input {
                    border: none;
                    color: #000;
                    box-shadow: none;
                    background-color: transparent;
                    background-image: url('/img/dashed-border.svg');
                    background-repeat: repeat-x;
                    background-position-y: bottom;
                    background-size: 5px 2px;
                    border-radius: 0;
                    font-size: 16px;
                    padding: 30px 0px;
                    margin-left: 15px;
    
                    &::placeholder {
                        color: #000;
                    }
    
                    &:focus {
                        border-color: #000;
                        box-shadow: none;
                        background-image: url('/img/dashed-border-hover.svg');
                    }
                }
                button {
                    height: 60px;
                    width: 60px;
                    background: #16321c;
                    border: none;
                    color: $color1;
                    margin-left: 15px;
                    margin-bottom: 30px;
                    
                    &:hover{
                        background: #fff;
                        color: #000;
                    }
                }
            }
        }

        address, .email, .tel {
            position: relative;
            
            span {
                position: absolute;
                left: 0;
                top: 4px;
                color: tint($color1, 50);
                font-size: 18px;
               
            }
        }

        address {
            font-style: normal;
            margin-left: 15px;
        }

        ul {
            list-style: none;
            margin: 0;
            padding: 0;

          
        }

        .btn {
            background: none;
            text-transform: uppercase;
            text-decoration: none;
            padding: 15px 30px;
            margin-top: 15px;
            margin-left: -15px;
            border-bottom: 2px solid #000;
            border-radius: 0;
            text-align: left;

            &:hover {
                color: #fff;
                border-color: #fff;
            }
        }

        .social {
            font-size: 42px;
            margin-left: 15px;
            text-decoration: none;
            margin-bottom: 30px;
            
            li {
                position: relative;
                display: inline-block;
                border: 2px solid $darkgreen;
                text-decoration: none;
                width: 40px;
                height: 40px;
                padding: 10px;
                margin-right: -2px;

                transition: all 300ms ease;

                a {
                    position: absolute;
                    top: 0;
                    left: 0;
                    text-decoration: none;
                    line-height: 43px;
                    text-align: center;
                    width: 100%;
                    height: 100%;
                    font-size: 25px;

                    transition: all 300ms ease;
                }

                &:hover {
                    border-color: #fff;

                    a {
                        color: #fff;
                    }
                }
            }
        }
    }

    .bottom {
        position: relative;
        z-index: 0;
        padding-top: 180px;
        padding-bottom: 20px;
        background: $color-gray;
        font-size: 16px;
        margin-left: -15px;
        margin-top: -150px;
        color: #c1bdb3;

        ul {
            margin-left: 30px;
            padding-left: 0;
            margin-bottom: 30px;

            li {
                list-style: none;
                text-align: left;
                margin-right: 0 !important;
                
                .devider {
                    display: inline-block;
                    padding: 0 30px;
                    display: none;
                }
            }
        }

        a {
            color: #c1bdb3;
        }

        .logos {
            text-align: left;
            margin-left: 15px;

            span {
                font-size: 24px;
                margin-left: 15px;
                color: #c1bdb3;
            }
        }
    }

}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    footer {
        margin-left: 30px;

        .top {
            padding: 30px 15px 50px 15px;

            form {
                .col-xs-12 {
                    padding-right: 0 !important;
                }
            }

            h3 {
                font-size: 40px;
                margin-left: 0px;
            }
            .social{
                margin-left: 15px;
            }
            .btn{
                margin-left: -15px;
            }

            address {
                margin-left: 15px;
            }
        }

        .bottom {
            margin-left: -30px;

            ul {
                text-align: center;
                
                li {
                    display: inline-block;

                    .devider {
                        display: inline-block;
                    }
                }
            }
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
    footer {
        

        .top {
            padding: 60px 0 60px;
            h3 {
                font-size: 44px;
                margin-left: 15px;
            }
            
            .address{
                margin-left: 0px;
                
            }
            
        }
        .bottom {
            ul {
                float: left;

                li {
                    display: inline-block;

                    &:not(:last-child) {
                        margin-right: 30px;
                    }
                }
            }

            .logos {
                float: right;
                top: 50%;
                position: absolute;
                right: 0;
                transform: translateY(-50%);
                text-align: left;

                span {
                    font-size: 24px;
                    margin-left: 15px;
                    color: #c1bdb3;
                }
            }
        }

    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
    footer {
    }
}


// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    footer {
        .bottom {
            // padding-top: 90px;
        }
    }
}