#homepage {
    background: #fff;
    #cta-holder {
        margin-top: -60px;
        margin-left: 30px;
        margin-right: 30px;
        .cta {
            margin-bottom: 10px;
            padding: 25px 10px;
            background: $color2;
            color: #fff;
            transition: all 300ms ease;
            i {
                font-size: 50px;
                margin-left: 10px;
            }
            h3 {
                color: #fff;
                font-size: 22px;
            }
            .btn {
                background: none;
                text-transform: uppercase;
                text-decoration: none;
                padding: 5px 30px;
                margin-top: 5px;
                border-bottom: 2px solid #fff;
                border-radius: 0;
                text-align: left;
                color: #fff;
                font-size: 16px;
                letter-spacing: 1px;
                margin-bottom: 10px;
                margin-right: 10px;
                &.first {
                    margin-left: -30px;
                }
                &.small {
                    margin-left: -30px;
                    padding: 5px 15px;
                }
                &:hover {
                    color: $darkgreen;
                    border-color: $darkgreen;
                }
            }
            &:hover {
                transform: scale(1.02);
            }
        }
    }
    #waarom {
        margin-top: 30px;
        .row {
            margin: 0 !important;
        }
        .list {
            h3 {
                font-weight: 900;
                color: $darkgreen;
                font-size: 32px;
                padding-left: 15px;
            }
            ul {
                margin: 0;
                padding: 0 0 30px 15px;
                li {
                    position: relative;
                    list-style: none;
                    padding-left: 25px;
                    padding-bottom: 5px;
                    font-size: 16px;
                    line-height: 32px;
                    &:before {
                        position: absolute;
                        content: '\e911';
                        font-family: 'icomoon';
                        color: $color1;
                        left: 0;
                    }
                }
            }
        }
        .employees {
            background: #f4f4f4;
            padding: 30px 15px;
            overflow: hidden;
            h2 {
                color: $color1;
                font-size: 30px;
                font-weight: 900;
            }
            .text {
                padding-bottom: 15px;
                font-size: 16px;
                z-index: 2;
                .btn {
                    background: none;
                    text-transform: uppercase;
                    text-decoration: none;
                    padding: 8px 15px;
                    margin-top: 5px;
                    margin-left: -15px;
                    border-bottom: 2px solid #000;
                    border-radius: 0;
                    text-align: left;
                    color: #000;
                    font-size: 16px;
                    letter-spacing: 1px;
                    &:hover {
                        color: $color2;
                        border-bottom: 2px solid $color2;
                    }
                }
            }
            .image {
                padding-top: 15px;
                z-index: 1;
                .bg {
                    position: absolute;
                    width: 700px;
                    right: -160px;
                    top: 70px;
                    z-index: 0;
                }
                img {
                    position: relative;
                    width: 70%;
                    float: right;
                    margin-bottom: -15px;
                }
                .info {
                    position: absolute;
                    background: #c1bdb4;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 18px;
                    padding: 15px;
                    max-width: 80%;
                    bottom: -45px;
                }
            }
        }
        .kennis {
            background: #17321c;
            font-size: 100px;
            text-align: center;
            padding: 30px 0;
        }
        .review {
            padding: 50px 15px 15px;
            p {
                position: relative;
                padding-left: 30px;
                margin: 0;
                strong {
                    display: block;
                    color: #c1bdb4;
                    text-transform: uppercase;
                    margin-top: 15px;
                }
                &:before {
                    content: ' ';
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 2px;
                    background: #c1bdb4;
                }
            }
        }
        .funda {
            padding: 30px 15px;
            background: $color1;
            h2 {
                color: #fff;
                font-size: 24px;
            }
            p {
                text-transform: uppercase;
                margin: 0;
                font-size: 16px;
                line-height: 30px;
                margin-left: -15px;
                span.grade {
                    display: inline-block;
                    font-size: 40px;
                    font-weight: 900;
                    margin-left: 15px;
                    line-height: 60px;
                }
            }
        }
    }
    #homeobjects {
        overflow: hidden;
        padding: 60px 0;
        margin: 0px 0 0 0;
        background: linear-gradient(to right, #F3F3F3 100%, #FFF 100%);
        .container {
            >.inner {
                padding: 0 30px;
                >h2 {
                    font-size: 34px;
                    font-weight: 7600;
                }
                >.inner {
                    margin-bottom: 30px;
                    p {
                        margin: 0;
                    }
                    .col-xs-3 {
                        position: relative;
                        i {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            font-size: 25px;
                            color: #C0BCB2;
                        }
                    }
                }
            }
        }
        #aanbod_carousel {
            padding: 0 30px;
            .slick-list {
                overflow: visible;
                .slick-slide {
                    margin-right: 30px;
                }
            }
        }
        a.btn {
            background: none;
            text-transform: uppercase;
            text-decoration: none;
            padding: 8px 15px;
            margin-top: 30px;
            margin-left: 15px;
            border-bottom: 2px solid #C0BCB2;
            border-radius: 0;
            text-align: left;
            color: #C0BCB2;
            font-size: 16px;
            letter-spacing: 1px;
            &:hover {
                color: $darkgreen;
                border-bottom: 2px solid $darkgreen;
            }
        }
        .buttons {
            float: right;
            margin-top: 30px;
            margin-right: 30px;
            .slick-arrow {
                display: block;
                height: 50px;
                width: 50px;
                text-align: center;
                line-height: 50px;
                float: left;
                font-size: 14px;
                cursor: pointer;
                &.prev {
                    color: #DAD7D0;
                }
                &.next {
                    color: #C0BDB2;
                    background: #fff;
                }
            }
        }
    }
    #news {
        margin-top: 60px;
        margin-bottom: 15px;
        overflow: hidden;
        padding: 0 30px;
        h2 {
            font-size: 30px;
        }
        .slick-list {
            overflow: visible;
        }
        .btn {
            background: none;
            text-transform: uppercase;
            text-decoration: none;
            padding: 8px 15px;
            margin-left: -15px;
            border-bottom: 2px solid #C1BDB4;
            border-radius: 0;
            text-align: left;
            color: #C1BDB4;
            font-size: 16px;
            letter-spacing: 1px;
            &:hover {
                color: $darkgreen;
                border-bottom: 2px solid $darkgreen;
            }
        }
        .buttons {
            float: right;
            margin-right: 30px;
            .slick-arrow {
                display: block;
                height: 50px;
                width: 50px;
                text-align: center;
                line-height: 50px;
                float: left;
                font-size: 14px;
                cursor: pointer;
                &.prev {
                    color: #DAD7D0;
                }
                &.next {
                    color: #C0BDB2;
                    background: #F3F3F3;
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    #homepage {
        #cta-holder {
            margin-top: 30px;
            .cta {
                padding: 30px;
                i {
                    margin-left: 0;
                }
                h3 {
                    font-size: 26px;
                }
            }
        }
        #waarom {
            .list {
                margin-top: 40px;
                h3 {
                    margin-bottom: 30px;
                }
            }
            .employees {
                padding: 40px;
            }
            .review {
                padding: 40px;
            }
            .funda {
                margin-top: 0;
                background-color: $color1;
                background-image: url('/img/funda-bg.png');
                background-repeat: no-repeat;
                background-position: calc(100% - 15px) center;
                background-size: auto 50%;
            }
        }
        #homeobjects {
            padding: 120px 0;
            margin: 50px 0 0 0;
            background: linear-gradient(to right, #F3F3F3 70%, #FFF 70%);
            .container {
                >.inner {
                    padding-left: 30px;
                    >.inner {
                        .col-xs-3 {
                            border-right: 1px solid #C0BCB2;
                        }
                    }
                }
            }
            .buttons {
                margin-right: calc(30% + 20px);
            }
        }
        #news {
            h2 {
                font-size: 38px;
                padding-left: 30px;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    #homepage {
        #cta-holder {
            .cta {
                padding: 40px 30px;
                margin-top: -60px;
                h3 {
                    font-size: 30px;
                }
            }
        }
        #waarom {
            .list {
                h3 {
                    font-size: 32px;
                }
            }
            .employees {
                h2 {
                    font-size: 50px;
                }
            }
            .kennis {
                font-size: 140px;
                i.icon {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                }
            }
            .review {
                padding: 100px 60px;
                strong {
                    letter-spacing: 1px;
                }
            }
            .funda {
                padding: 50px 30px;
            }
        }
        #homeobjects {
            background: linear-gradient(to right, #F3F3F3 60%, #FFF 60%);
            .container {
                >.inner {
                    >h2 {
                        font-size: 50px;
                    }
                    >.inner {
                        .col-xs-3 {
                            i {
                                font-size: 30px;
                            }
                        }
                        p {
                            line-height: 30px;
                        }
                    }
                }
            }
            .buttons {
                margin-right: calc(40% + 30px);
            }
        }
        #news {
            margin-top: 100px;
            margin-bottom: 30px;
            .buttons {
                margin-right: calc(50% + 60px);
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    #homepage {
        #cta-holder {
            .cta {
                padding: 60px 30px;
                i {
                    font-size: 70px;
                }
                .btn {
                    &.small {
                        margin-left: 0;
                    }
                    &.first {
                        margin-left: -30px;
                    }
                }
            }
        }
        #waarom {
            margin-top: 100px;
            margin-bottom: 100px;
            .list {
                margin-top: 80px;
                ul {
                    li {
                        padding-bottom: 10px;
                    }
                }
            }
            .employees {
                padding: 80px 60px;
                .image {
                    position: absolute;
                    bottom: 0;
                    right: 60px;
                    .bg {
                        width: 900px;
                        right: -235px;
                        top: 120px;
                    }
                    img {
                        margin-bottom: 0;
                        width: 80%;
                    }
                    .info {
                        max-width: 70%;
                        padding: 20px 30px;
                        margin-left: 5%;
                        font-size: 16px;
                        float: left;
                        bottom: -25px;
                    }
                }
                .text {
                    p {
                        line-height: 32px;
                    }
                    .btn {
                        padding: 15px 30px;
                        margin-left: -30px;
                    }
                }
            }
            .review {
                padding: 80px 60px;
                p {
                    padding: 30px 60px 30px;
                    font-size: 24px;
                    strong {
                        margin-left: 30px;
                        font-size: 16px;
                    }
                }
            }
            .funda {
                padding-top: 80px;
                padding-bottom: 80px;
                padding-left: 50px;
                background-size: auto 80%;
                p {
                    span.grade {
                        position: absolute;
                        top: -20px;
                        font-size: 60px;
                    }
                }
            }
        }
        #homeobjects {
            background: linear-gradient(to right, #F3F3F3 53%, #FFF 53%);
            margin: 0;
            .container {
                >.inner {
                    >.inner {
                        .col-xs-3 {
                            i {
                                font-size: 40px;
                            }
                        }
                    }
                }
            }
            #aanbod_carousel {
                .slick-list {
                    .slick-slide {
                        margin-right: 60px;
                    }
                }
            }
            .buttons {
                margin-right: calc(50% + 60px);
            }
        }
        #news {
            h2 {
                font-size: 50px;
            }
            article {
                padding-right: 30px;
                img {
                    width: 170px
                }
                .text {
                    margin-left: 85px;
                    padding-left: 135px;
                    padding-bottom: 60px;
                }
            }
        }
    }
}