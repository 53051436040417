header {
    position: relative;
    .topheader {
        display: none;
    }
    .nav_wrapper {
        position: fixed;
        left: 0;
        top: 0;
        z-index: 10;
        width: 100%;
        background-color: $header-background-color-mobile;
        transition: background-color 0.2s;
        max-height: 65px;
        min-height: 65px;
        box-shadow: 0 0 50px rgba(0, 0, 0, 0.05);
        .container-fluid {
            position: relative;
            .logo {
                position: relative;
                z-index: 11;
                display: block;
                height: 80px;
                margin: 0 0 0 15px;
                background: #fff;
                top: 10px;
                transition: all 300ms ease;
            }
            .button_wrapper {
                position: absolute;
                top: 0;
                right: 10px;
                ul {
                    display: block;
                    padding: 0;
                    margin: 0;
                    list-style: none;
                    li {
                        display: inline-block;
                        a {
                            display: block;
                            line-height: 40px;
                            padding: 15px 5px;
                            text-align: center;
                            font-size: 22px;
                        }
                        &.phone-mob {
                            a {
                                position: relative;
                                top: -4px;
                                padding: 0 15px;
                                font-size: 14px !important;
                                letter-spacing: 1px;
                                font-weight: 500;
                                color: $darkgreen;
                            }
                        }
                    }
                }
            }
            nav {
                position: absolute;
                right: 0px;
                top: 60px;
                width: 100%;
                height: calc(100vh - 60px);
                display: none;
                z-index: 10;
                overflow: auto;
                >ul {
                    list-style: none;
                    margin: 0px;
                    padding: 50px 0 0;
                    background: #FFF;
                    li {
                        border-top: 1px solid #EEE;
                        a {
                            display: block;
                            text-decoration: none;
                            line-height: 30px;
                            padding: 5px 30px;
                            user-select: none;
                            color: $darkgreen;
                            font-size: 14px;
                            text-transform: uppercase;
                            letter-spacing: 1px;
                            font-weight: 500;
                            &:hover,
                            &.active {
                                color: $color1-hover;
                            }
                            span {
                                display: block;
                                font-size: 12px;
                                float: right;
                                line-height: 30px !important;
                                transform: rotate(180deg);
                                transition: all 0.2s;
                                &.toggle {
                                    transform: rotate(0deg);
                                }
                            }
                        }
                        >ul {
                            padding: 0;
                            display: none;
                            >li {
                                a {
                                    white-space: nowrap;
                                    padding-left: 45px;
                                    transition-property: 10%;
                                    font-weight: 500;
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
        &.fixed {
            background: $header-background-color-fixed;
        }
    }
    #header_carousel {
        left: 0;
        top: 0;
        width: 100%;
        background: #EEE;
        padding-top: 70px;
        .slick-list,
        .slick-track {
            height: 100%;
            overflow: visible;
        }
        .header_slide {
            position: relative;
            width: 100%;
            height: 100%;
            background-size: cover;
            background-position: center center;
            background-repeat: no-repeat;
            .header_payoff {
                position: absolute;
                top: 60%;
                left: -30px;
                transform: translateY(-50%);
                h1 {
                    font-size: 22px;
                }
                .title {
                    display: inline;
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 40px;
                    font-weight: 900;
                    line-height: 50px;
                    span {
                        display: inline-block;
                        padding: 0 30px 0 57px;
                        margin-bottom: 10px;
                        background: $color1;
                    }
                }
                a.btn {
                    margin-left: 60px;
                    border-radius: 0;
                    background: #fff;
                    color: $color2;
                    text-transform: uppercase;
                    font-size: 16px;
                    letter-spacing: 1px;
                    &:hover {
                        background: $color2;
                        color: #fff;
                    }
                }
                h1,
                h2,
                h3 {
                    color: $darkgreen;
                }
                h2,
                h3 {
                    font-weight: 700;
                    color: #FFF;
                }
                h2 {
                    font-size: 28px;
                }
                h3 {
                    font-family: $font2;
                    font-size: 20px;
                    margin-top: 10px;
                }
                .btn {
                    margin: 20px 5px 0 5px;
                }
            }
            .container {
                position: relative;
                height: 100%;
                .header_payoff {
                    top: 50%;
                    left: 0;
                    h2 {
                        color: $darkgreen;
                        margin-left: 30px;
                    }
                }
            }
        }
    }
    &.vervolg {
        height: auto;
        min-height: 250px;
        #header_carousel {
            height: 250px;
            .header_slide {
                background: $color-gray;
                .header_payoff {
                    padding: 0 30px;
                    max-width: 60%;
                    h1 {
                        margin-bottom: 0;
                    }
                    p {
                        display: none;
                    }
                    &.news {
                        max-width: 900px;
                        .date {
                            position: relative;
                            padding-left: 30px;
                            color: $color2;
                            strong {
                                text-transform: uppercase;
                            }
                            &:before {
                                content: ' ';
                                position: absolute;
                                top: 50%;
                                left: 0;
                                transform: translateY(-50%);
                                height: 2px;
                                width: 20px;
                                background: $color2;
                            }
                        }
                    }
                }
            }
        }
    }
    &.aanbod {
        height: auto;
        min-height: 460px;
        background: transparent;
        #header_carousel {
            height: 460px;
            .header_slide {
                background: $color-gray;
                .header_payoff {
                    padding: 0 30px;
                    max-width: 70%;
                    h1 {
                        margin-bottom: 0;
                    }
                    p {
                        display: none;
                    }
                }
            }
        }
    }
    &.contact {
        min-height: 250px;
        #header_carousel {
            height: 250px;
            .header_slide {
                background: $color-gray;
                .header_payoff {
                    padding-left: 30px;
                    top: 60%;
                    max-width: 100%;
                    h1 {
                        margin-bottom: 30px;
                    }
                    p {
                        display: block;
                    }
                }
            }
        }
    }
    &.object {
        height: auto;
        min-height: 70px;
    }
}

// *****************************************************************
// SM
// *****************************************************************
@media (min-width: $screen-sm-min) {
    header {
        .nav_wrapper {
            max-height: initial;
            min-height: initial;
        }
        #header_carousel {
            .header_slide {
                .header_payoff {
                    top: 70%;
                }
            }
        }
        &.vervolg {
            min-height: 300px;
            #header_carousel {
                height: 300px;
                .header_slide {
                    .header_payoff {
                        max-width: 50%;
                        top: 70%;
                        h1 {
                            font-size: 40px;
                        }
                    }
                }
            }
        }
        &.aanbod {
            #header_carousel {
                min-height: 430px;
            }
        }
        &.contact {
            #header_carousel {
                .header_slide {
                    .header_payoff {
                        max-width: 300px;
                    }
                }
            }
        }
    }
}

// *****************************************************************
// MD
// *****************************************************************
@media (min-width: $screen-md-min) {
    .phone-background {
        position: fixed;
        top: 0;
        right: 0;
        height: 120px;
        width: 230px;
        background: #f4f4f4;
        z-index: -1;
        &.vervolg,
        &.aanbod,
        &.contact {
            background: $color2;
        }
    }
    header {
        min-height: 590px;
        .topheader {
            display: block;
            position: relative;
            z-index: 1;
            background: $color1;
            color: #FFF;
            line-height: 40px;
            height: 40px;
            font-size: 16px;
            overflow: hidden;
            strong {
                color: $color-stars;
            }
            .logo_wrapper {
                float: left;
                margin-left: 30px;
                span {
                    font-size: 24px;
                    line-height: inherit;
                    color: rgba(255, 255, 255, 0.5);
                    margin-right: 10px;
                }
            }
            .contact_wrapper {
                float: right;
                a {
                    display: inline-block;
                    position: relative;
                    overflow: hidden;
                    color: $color1-text;
                    margin-left: 30px;
                    span {
                        float: left;
                        font-size: 20px;
                        line-height: inherit;
                        color: rgba(255, 255, 255, 0.5);
                        margin-right: 10px;
                    }
                }
            }
        }
        .nav_wrapper {
            position: absolute;
            top: 0px;
            background: linear-gradient(to right, $header-background-color 95%, #f4f4f4 95%);
            min-height: 90px;
            .container-fluid {
                .logo {
                    position: absolute;
                    height: 100px;
                    margin: 5px 0 0 15px;
                }
                nav {
                    display: block !important;
                    padding: 0;
                    top: 0;
                    right: 15px;
                    width: auto;
                    height: auto;
                    overflow: visible;
                    background: none;
                    transition: all 0.2s;
                    >ul {
                        float: left;
                        border-bottom: none;
                        white-space: nowrap;
                        background: none;
                        padding: 0;
                        >li {
                            border-top: none;
                            position: relative;
                            display: inline-block;
                            a {
                                position: relative;
                                padding: 0px 6px;
                                font-size: 14px;
                                color: $darkgreen;
                                text-transform: uppercase;
                                font-weight: 500;
                                line-height: 90px;
                                i {
                                    transform: none;
                                    margin-left: 5px;
                                    &.icon-home {
                                        color: $color1;
                                        font-size: 20px;
                                    }
                                }
                            }
                            >a {
                                &:before {
                                    content: '';
                                    position: absolute;
                                    height: 10px;
                                    width: 10px;
                                    background: $color2;
                                    top: 20%;
                                    left: 50%;
                                    transform: translateX(-50%);
                                    opacity: 0;
                                    transition: all 300ms ease;
                                }
                                &.no-block {
                                    &:before {
                                        display: none;
                                    }
                                }
                            }
                            &.phone {
                                background: $color2;
                                width: 215px;
                                text-align: center;
                            }
                            &:hover>a,
                            >a.active {
                                position: relative;
                                background: none;
                                &:before {
                                    opacity: 1;
                                }
                            }
                            >ul {
                                position: absolute;
                                top: 100%;
                                left: -5px;
                                background: $color1;
                                color: $color1-text;
                                text-align: left;
                                display: block;
                                z-index: 10;
                                overflow: hidden;
                                transition: all 0.3s;
                                transform: translate(0, 15px);
                                opacity: 0;
                                visibility: hidden;
                                border: 0;
                                >li {
                                    display: block;
                                    border-top: none;
                                    a {
                                        padding: 5px 15px;
                                        text-transform: uppercase;
                                        font-weight: 500;
                                        color: $color1-text;
                                        line-height: 30px;
                                        &:hover {
                                            color: $darkgreen;
                                        }
                                        &.active {
                                            background: rgba(0, 0, 0, 0.2);
                                            color: #FFF;
                                        }
                                    }
                                }
                            }
                            &:hover {
                                ul {
                                    transform: none;
                                    opacity: 1;
                                    visibility: visible;
                                }
                            }
                        }
                    }
                    .btn {
                        margin-left: 10px;
                        margin-top: -8px;
                        font-size: 16px;
                        padding: 10px;
                    }
                }
                .button_wrapper {
                    transition: all 0.2s;
                }
            }
            &.fixed {
                position: fixed;
                top: 0;
                background: linear-gradient(to right, $header-background-color 95%, #f4f4f4 95%);
            }
        }
        #header_carousel {
            padding-top: $header-carousel-padding-md;
            .header_slide {
                .header_payoff {
                    .title {
                        font-size: 40px;
                        line-height: 80px;
                    }
                    h1 {
                        font-size: 48px;
                    }
                    h2 {
                        font-size: 28px;
                    }
                    .btn {
                        margin: 20px 5px 0 5px;
                    }
                }
            }
        }
        &.vervolg {
            min-height: 350px;
            #header_carousel {
                height: 350px;
                .header_slide {
                    .header_payoff {
                        top: 55%;
                        h1 {
                            margin-bottom: 30px;
                        }
                        p {
                            display: block;
                        }
                    }
                }
            }
            .nav_wrapper {
                background: linear-gradient(to right, $header-background-color 95%, $color2 95%);
                .container-fluid {
                    nav {
                        >ul {
                            >li {
                                &.phone {
                                    background: $color2;
                                    a {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        &.aanbod {
            .nav_wrapper {
                background: linear-gradient(to right, $header-background-color 95%, $color2 95%);
                .container-fluid {
                    nav {
                        >ul {
                            >li {
                                &.phone {
                                    background: $color2;
                                    a {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #header_carousel {
                min-height: 550px;
            }
        }
        &.contact {
            .nav_wrapper {
                background: linear-gradient(to right, $header-background-color 95%, $color2 95%);
                .container-fluid {
                    nav {
                        >ul {
                            >li {
                                &.phone {
                                    background: $color2;
                                    a {
                                        color: #fff;
                                    }
                                }
                            }
                        }
                    }
                }
            }
            #header_carousel {
                min-height: 300px;
                padding-top: 90px;
                .header_slide {
                    min-height: 300px;
                    .header_payoff {
                        max-width: 40%;
                    }
                }
            }
        }
        &.object {
            min-height: $header-carousel-padding-md;
        }
    }
}

// *****************************************************************
// LG
// *****************************************************************
@media (min-width: $screen-lg-min) {
    header {
        min-height: 740px;
        .topheader {
            line-height: 50px;
            height: 50px;
            font-size: 18px;
        }
        .nav_wrapper {
            top: 0px;
            .container-fluid {
                .logo {
                    height: 110px;
                    margin: 15px 0;
                    transition: all 0.2s;
                    position: absolute;
                    background: #fff;
                    margin-left: 45px;
                }
                nav {
                    // top: 30px;
                    >ul {
                        margin-left: 0px;
                        >li {
                            a {
                                font-size: 14px;
                                padding: 0 25px;
                                color: $darkgreen;
                                letter-spacing: 1px;
                            }
                        }
                    }
                    .btn {
                        font-size: 18px;
                    }
                }
            }
            &.fixed {
                .container-fluid {
                    position: relative;
                    // .logo {
                    //     height: 50px;
                    //     margin: 10px 0;
                    // }
                    .button_wrapper {
                        top: 16px;
                    }
                }
            }
        }
        #header_carousel {
            margin: 0px 30px 0px 30px;
            background: #fff;
            max-width: calc(100% - 60px);
            padding: 0;
            .header_slide {
                .header_payoff {
                    .title {
                        font-size: 60px;
                        line-height: 100px;
                    }
                    h1 {
                        font-size: 60px;
                    }
                    h2 {
                        font-size: 36px;
                    }
                }
            }
        }
        &.vervolg {
            max-height: 350px;
            #header_carousel {
                max-height: 350px;
                .header_slide {
                    .header_payoff {
                        max-width: 720px;
                        top: 60%;
                        h1 {
                            font-size: 50px;
                        }
                    }
                }
            }
        }
        &.object {
            min-height: $header-carousel-padding-lg;
        }
        &.aanbod {
            #header_carousel {
                min-height: 700px;
            }
        }
        &.contact {
            #header_carousel {
                min-height: 480px;
            }
        }
    }
}

@media (min-width: 1500px) {
    header {
        &.aanbod {
            #header_carousel {
                min-height: 720px;
            }
        }
        &.contact {
            #header_carousel {
                .header_slide {
                    .header_payoff {
                        max-width: 500px;
                    }
                }
            }
        }
    }
}