#form-page {
    margin-top: 50px;
    
    h2 {
        color: $color1;
        font-size: 30px;
        font-weight: 900;
    }
    form {
        margin-bottom: 30px;

        .form-group {
            margin-bottom: 30px;

            .form-control {
                color: $darkgreen;
                border: none;
                box-shadow: none;

                background-color: transparent;
                background-image: url('/img/dashed-border.svg');
                background-repeat: repeat-x;
                background-position-y: bottom;
                background-size: 5px 2px;
                border-radius: 0;

                &::placeholder {
                    color: $darkgreen;
                }
            }
        }

        button {
            background: $color2;
            color: #fff;
            font-size: 16px;
            font-weight: 700;
            border-radius: 0;
            text-transform: uppercase;
            letter-spacing: 1px;
            padding: 15px 30px;
            border: none;
        }
    }
    small {
        color: #C1BDB4;

        a {
            color: #C1BDB4;
            text-decoration: underline;
        }
    }
}

@media (min-width: $screen-sm-min) {
    #form-page {
        form {
            .form-group {
                .form-control {
                    font-size: 16px;
                    height: initial;
                }
            }
        }

        small {
            display: block;
            max-width: 80%;
        }
    }
}

@media (min-width: $screen-md-min) {
    #form-page {
       
        h2{
            font-size: 50px;
        }

       
    }
}