.object_wrapper {
    .object {
        display: block;
        position: relative;
        border-radius: 5px;
        margin-bottom: 30px;

        figure {
            position: relative;
            width: 90%;
            overflow: hidden;

            img {
                display: block;
                width: 100%;
                transition: all 300ms ease;
            }

            figcaption {
                opacity: 0;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
                transition: all 0.3s;

                ul {
                    position: absolute;
                    left: 0;
                    bottom: 15px;
                    width: 100%;
                    list-style: none;
                    margin: 0;
                    padding: 0;
                    text-align: center;

                    li {
                        display: inline-block;
                        color: #FFF;
                        font-size: 18px;

                        &:not(:last-child) {
                            margin-right: 10px;
                        }
                    }
                }
            }

            .label {
                position: absolute;
                bottom: 0px;
                left: 0;
                display: inline-block;
                background: $color1;
                color: #fff;
                padding: 10px 30px;
                font-weight: 500;
                text-transform: uppercase;
                letter-spacing: 1px;

                &.onder-bod,
                &.verkocht-onder-voorbehoud {
                    background: #ffb81c;
                }
                &.verkocht {
                    background: #d0091d;
                }
            }
        }

        .text {
            box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.1);
            padding: 80px 35px 85px 35px;
            margin-top: -40px;
            overflow: hidden;
            background: #fff;

            h2 {
                position: relative;
                color: $darkgreen;
                font-size: 13px;
                margin-bottom: 0;
                text-transform: uppercase;
                letter-spacing: 1px;

                &:before {
                    content: ' ';
                    position: absolute;
                    top: 7px;
                    left: -65px;
                    width: 50px;
                    height: 2px;
                    background: $darkgreen;
                }
            }

            h3 {
                color: $darkgreen;
                font-weight: 700;
                font-size: 18px;
                margin: 0;
            }

            h4 {
                color: $color2;
                font-size: 20px;
                font-weight: 600;
                margin: 0;
            }

            ul {
                position: absolute;
                padding: 0;
                margin: 0;
                bottom: 35px;
                left: 35px;
                
                li {
                    position: relative;
                    display: inline-block;
                    color: #C0BCB2;
                    margin-right: 15px;
                    padding-left: 35px;
                    font-weight: 600;

                    i {
                        position: absolute;
                        left: 0;
                        font-size: 25px;
                    }
                }
            }
        }

        &:hover {
            figure {
                figcaption {
                    opacity: 1;
                }

                img {
                    transform: scale(1.05);
                }
            }
        }
    }
}

@media (min-width: $screen-sm-min) {
    .object_wrapper {
        .object {
            .text {
                h2 {
                    font-size: 16px;

                    &:before {
                        top: 9px;
                    }
                }
                h3 {
                    font-size: 22px;
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    .object_wrapper {
        .object {
            .text {
                h3 {
                    font-size: 30px;
                }
            }
        }
    }
}