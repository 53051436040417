#object {
    background: #f2f2f6;

    .fotoheader {
        position: relative;
        overflow: hidden;

        img {
            display: block;
            width: 100%;
        }

        figcaption {
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            background: linear-gradient(to top, rgba(0, 0, 0, 0.5) 0%, transparent 100%);
            padding: 15px 0;

            h1, h2 {
                color: #FFF;
                margin-bottom: 0;
            }

            h2 {
                font-family: $font2;
                font-weight: normal;
                font-size: 20px;
            }

            h3.openhuis {
                display: inline-block;
                padding: 10px 15px;
                margin-top: 15px;
                background: $color2;
                color: $color2-text;
                border-radius: 5px;
            }
        }

        span {
            position: absolute;
            right: 15px;
            top: 15px;
            font-size: 24px;
            width: 40px;
            height: 40px;
            line-height: 40px;
            text-align: center;
            border-radius: 20px;
            background: $color2;
            color: $color2-text;
        }
    }

    #wrapper_omschrijving {
        padding-top: 15px;

        .floater {
            border-radius: 5px;
            background: #FFF;
            margin-bottom: 30px;
            box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.1);

            .status {
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                display: block;
                padding: 10px 15px;
                background: #b8c627;
                color: #FFF;
                text-transform: uppercase;
            }

            .floater_content {
                padding: 0px 15px 0px 15px;

                h3 {
                    font-size: 30px;
                    margin: 15px 0;
                }

                h4 {
                    font-family: $font2;
                    color: #999;
                    text-transform: uppercase;
                    font-size: 18px;
                    letter-spacing: 3px;
                    margin: 0 0 15px 0;
                }

                img {
                    border-radius: 40px;
                    float: left;
                    margin-top: 5px;
                    margin-right: 15px;
                    width: 80px;
                    height: 80px;
                }

                ul {
                    list-style: none;
                    margin: 0;

                    li {
                        a {
                            color: #202020;

                            span {
                                display: inline-block;
                                color: $color1;
                                width: 25px;
                            }
                        }
                    }
                }

                .btn {
                    margin-bottom: 15px;

                    span {
                        margin-right: 10px;
                    }
                }
            }
        }

        #omschrijving {
            position: relative;
            max-height: 270px;
            overflow: hidden;
            color: rgba(#202020, 0.7);
            transition: all 1s;
            transition: all 1s;

            &:after {
                content: ' ';
                position: absolute;
                left: 0;
                top: 0;
                display: block;
                width: 100%;
                height: 270px;
                background: linear-gradient(to top, $color-gray 0%, transparent 100%);
            }

            + a {
                font-weight: bold;
                margin-top: 30px;
            }

            &.show {
                max-height: 2000px;

                &:after {
                    content: none;
                }
            }
        }
    }

    #wrapper_details {
        padding-bottom: 15px;

        .details {
            h3 {
                margin-bottom: 15px;
            }

            table {
                width: 100%;
                margin-bottom: 30px;
                font-size: 16px;

                tr {
                    &:first-child {
                        td {
                            border-top: 1px solid #e7e7eb;
                        }
                    }

                    td {
                        width: 50%;
                        padding: 5px 0;
                        border-bottom: 1px solid #e7e7eb;

                        &:first-child {
                            color: #999999;
                        }
                    }
                }
            }
        }
    }

    #wrapper_foto {
        padding-bottom: 15px;
    }

    #wrapper_streetview,
    #wrapper_locatie {
        iframe {
            border: 0;
            width: 100%;
            height: 500px;
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
    #object {
        .fotoheader {
            figcaption {
                padding: 30px 0;

                h2 {
                    font-size: 24px;
                }
            }

            span {
                top: auto;
                bottom: 30px;
                right: 30px;
                width: 50px;
                height: 50px;
                line-height: 50px;
                border-radius: 25px;
                font-size: 28px;
            }
        }

        #wrapper_omschrijving {
            .floater {
                margin-bottom: 45px;
                border-radius: 5px;
                background: #FFF;
                box-shadow: 0px 15px 40px 0px rgba(0, 0, 0, 0.1);

                .status {
                    padding: 10px 30px;
                }

                .floater_content {
                    overflow: hidden;
                    padding: 0 30px 30px 30px;
                }
            }
        }

        #wrapper_foto {
            padding-bottom: 30px;

            .foto {
                margin-bottom: 30px;

                img {
                    display: block;
                    width: 100%;
                }
            }
        }
    }
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
    #object {
        .fotoheader {
            img {
                float: left;
                width: 20%;
            }

            img:first-of-type {
                width: 60%;
            }

            img:nth-of-type(2) {
                width: 40%;
            }

            figcaption {
                padding: 45px 0;

                h1 {
                    font-size: 40px;
                }

                h2 {
                    font-size: 30px;
                }
            }

            span {
                bottom: 45px;
                right: 45px;
                width: 60px;
                height: 60px;
                line-height: 60px;
                border-radius: 30px;
                font-size: 32px;
            }
        }

        nav {
            background: #FFF;
            padding: 30px 0 15px 0;

            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                text-align: center;

                li {
                    display: inline-block;
                    margin: 0 5px 15px 5px;

                    a {
                        display: block;
                        padding: 8px 15px;
                        border: 1px solid #d8d2ef;
                        border-radius: 5px;
                        color: #202020;

                        &:hover {
                            background: #f2f2f6;
                        }
                    }
                }
            }
        }

        #wrapper_details {
            padding-bottom: 30px;
        }

        #wrapper_foto {
            padding-bottom: 30px;
        }
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    #object {
        .fotoheader {
            figcaption {
                padding: 60px 0;

                h1 {
                    font-size: 60px;
                }

                h2 {
                    font-size: 40px;
                }
            }

            span {
                right: 60px;
                bottom: 60px;
                width: 80px;
                height: 80px;
                line-height: 80px;
                border-radius: 40px;
                font-size: 48px;
            }
        }

        #wrapper_omschrijving {
            padding-top: 100px;

            .container-fluid {
                position: relative;
            }

            .floater {
                position: absolute;
                width: calc(33.33333333% - 30px);
                top: 0;
                right: 15px;
                transition: all 0.3s;

                .floater_content {
                    h3 {
                        margin: 30px 0;
                    }

                    .floater_contact {
                        margin-bottom: 30px;
                    }
                }
            }
        }

        #wrapper_details {
            padding-bottom: 70px;
        }

        #wrapper_foto {
            padding-bottom: 70px;
        }
    }
}