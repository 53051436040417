#contact {
    .container {
        .form {
            margin-top: 30px;
        }
        .contact-side {
            color: $darkgreen;
            padding: 15px;
            font-size: 16px;
            margin-top: 30px;
            

            img {
                width: 100%;
                box-shadow: 20px 35px 60px 0px rgba(0, 0, 0, 0.1);
                margin-bottom: 60px;
            }

            h2 {
                font-size: 30px;
                font-weight: 900;
                color: $color1;
            }

            a {
                color: $darkgreen;
                text-decoration: underline;
                
                &:hover {
                    color: $color1; 
                }
            }

            .btn {
                background: none;
                text-transform: uppercase;
                text-decoration: none;
                padding: 15px 15px;
                margin-left: -15px;
                border-bottom: 2px solid $darkgreen;
                border-radius: 0;
                text-align: left;
                color: $darkgreen;
                font-size: 16px;
                letter-spacing: 1px;
            }
        }

        .social-side {
            font-weight: 600;
            width: 400px;

            p {
                margin-bottom: 15px;
            }

            ul {
                margin: 0;
                padding: 0;
                list-style: none;

                li {
                    float: left;

                    a {
                        display: block;
                        color: $darkgreen;
                        height: 40px;
                        width: 40px;
                        border: 2px solid $darkgreen;
                        text-align: center;
                        line-height: 40px;
                        font-size: 24px;
                        margin-right: 15px;
                        margin-bottom: 15px;
                        transition: all 300ms ease;
                        text-decoration: none;

                        &:hover {
                            color: $color1;
                            border-color: $color1;
                        }
                    }
                }
            }
        }
    }
}

// ***************************************************************** 
// SM
// ***************************************************************** 

@media (min-width: $screen-sm-min) {
}

// ***************************************************************** 
// MD
// ***************************************************************** 

@media (min-width: $screen-md-min) {
    #contact {
        .container {
            .col-md-6 {
                &:first-child {
                    padding-right: 30px;
                }
                &:last-child {
                    padding-left: 30px;
                }
            }
            .contact-side {
                margin-top: -245px;

                h2 {
                    font-size: 50px;
                }
            }
            .social-side {
                width: 270px;
                padding-top: 0;
            }
        }
    }
}

// ***************************************************************** 
// LG
// ***************************************************************** 

@media (min-width: $screen-lg-min) {
    #contact {
        .container {
            .form {
                padding-right: 30px;
                padding-left: 45px;
            }

            .contact-side {
                margin-top: -345px;
            }
            .social-side {
                ul {
                    li {
                        &:nth-child(3) {
                            a {
                                // margin-right: 160px;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media (min-width: 1600px) {
    #contact {
        .container {
            .contact-side {
                img {
                    width: 120%;
                }
            }
        }
    }
}