article.newsarticle {
    position: relative;
    margin-top: 30px;
    margin-right: 30px;

    img {
        position: absolute;
        width: 100px;
        left: 0;
        top: -15px;
        z-index: 1;
    }
    .text {
        background: #fff;
        box-shadow: 20px 35px 60px 0px rgba(0, 0, 0, 0.1);
        margin-left: 50px;
        padding: 15px 15px 15px 70px;
        margin-bottom: 70px;
        color: $darkgreen;
        font-size: 14px;

        .title {
            position: relative;
            color: $color2;
            padding-bottom: 15px;
            font-size: 12px;

            strong {
                text-transform: uppercase;
                letter-spacing: 1px;
            }

            &:before {
                content: ' ';
                position: absolute;
                height: 2px;
                width: 50px;
                left: -55px;
                top: 9px;
                background: $color2;
            }
        }
    }
}

@media (min-width: $screen-md-min) {
    article.newsarticle {
        img {
            width: 120px;
        }
        .text {
            padding-left: 100px;
            font-size: 20px;

            .title {
                font-size: 16px;

                &:before {
                    left: -65px;
                    top: 10px;
                }
            }
        }
    }
}

@media (min-width: $screen-lg-min) {
    article.newsarticle {
        padding-right: 30px;
        
        img {
            width: 170px
        }
        .text {
            margin-left: 85px;
            padding-left: 135px;
            padding-bottom: 60px;
        }
    }
}