@import url('https://fonts.googleapis.com/css?family=Work+Sans:400,500,600,700,800,900');

$font1: 'Work Sans', serif;
$font2: 'Work Sans', sans-serif;

$color1: #3baa34;
$color1-hover: #ec671a;
$color1-text: #FFF;
$darkgreen: #16321c;

$color2: #ec671a;
$color2-hover: tint($color2, 10);
$color2-text: $color1;

$color-gray: #f4f5f4;

$color-stars: #ffe200;

$nav-color-mobile: #ec671a;
$nav-color: #ec671a;
$nav-color-fixed: #ec671a;

$header-background-color-mobile: #FFF;
$header-background-color: #FFF;

$header-background-color-fixed: #FFF;

$header-carousel-padding-md: 110px; // Top + navbar / set to 0 for overlay nav
$header-carousel-padding-lg: 60px; // Top + navbar / set to 0 for overlay nav